@font-face {
	font-family: "icons";
	src: url("../icons/icons.eot?a45c4ceb7de64ca6ffca8391788e1ec7?#iefix") format("embedded-opentype"),
url("../icons/icons.woff?b74ccf546f92b8739582ad126e5462ff") format("woff"),
url("../icons/icons.woff2?1aaa0c32b115df4a948a18c03af973ce") format("woff2"),
url("../icons/icons.ttf?bce47ab8318f901c3a5bf45009af4806") format("truetype"),
url("../icons/icons.svg?1929ab8903daf820482fb7d4f0aed3c8#icons") format("svg");
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
}


.icon-angle-down:before {
	content: "\f101";
}
.icon-angle-left:before {
	content: "\f102";
}
.icon-angle-right:before {
	content: "\f103";
}
.icon-angle-up:before {
	content: "\f104";
}
.icon-cancel:before {
	content: "\f105";
}
.icon-close:before {
	content: "\f106";
}
.icon-facebook:before {
	content: "\f107";
}
.icon-gplus:before {
	content: "\f108";
}
.icon-logout:before {
	content: "\f109";
}
.icon-menu:before {
	content: "\f10a";
}
.icon-mobile:before {
	content: "\f10b";
}
.icon-ok:before {
	content: "\f10c";
}
.icon-phone:before {
	content: "\f10d";
}
.icon-search:before {
	content: "\f10e";
}
.icon-telegram:before {
	content: "\f10f";
}
.icon-user:before {
	content: "\f110";
}
.icon-youtube:before {
	content: "\f111";
}
